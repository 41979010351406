import React, {lazy, Suspense} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/styles';
import {DefaultTheme} from './styles/themes';
import InvitationsForGuest from './screens/invitations/guests/InvitationsForGuest';
import UserActivation from './screens/invitations/guests/UserActivation';
import NotFound from './screens/error/NotFound';

const Invitations = lazy(() => import('./screens/invitations/Invitations'));
const Completed = lazy(() => import('./screens/invitations/Completed'));
const addOrganization = lazy(() => import('./screens/invitations/organizations/AddOrganizations'));
const ResetPassword = lazy(() => import('./screens/reset-password/ResetPassword'));
const ResetCompleted = lazy(() => import('./screens/reset-password/ResetCompleted'));

const Loading: React.FC = () => {
  return <div />;
};

const Root: React.FC = () => {
  return <NotFound />;
};

const Router: React.FC = () => {
  return (
    <ThemeProvider theme={DefaultTheme}>
      <BrowserRouter>
        <Suspense fallback={Loading}>
          <Switch>
            <Route path={'/'} exact={true} component={Root} />
            <Route path={'/invitations'} exact={true} component={Invitations} />
            {/*なんかよくわからないけどBKPから帰ってくるurlの形が変わってくるから下記で暫定対応*/}
            {/*変え切っちゃうと後方互換が保てないからinvitationsも残しておく*/}
            <Route path={'/inviteMember'} exact={true} component={Invitations} />
            <Route path={'/invitations/guests'} exact={false} component={InvitationsForGuest} />
            <Route path={'/invitations/activate'} exact={true} component={UserActivation} />
            <Route path={'/invitations/addOrganization'} exact={true} component={addOrganization} />
            <Route path={'/completed'} exact={true} component={Completed} />
            <Route path={'/resetPassword'} exact={true} component={ResetPassword} />
            <Route path={'/reset-completed'} exact={true} component={ResetCompleted} />

            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default Router;
