import BitkeyPlatformAPI from './bkp/bitkey-platform-api';
import Logger, {LogLevel} from './common/logger/logger';

const initLogger = () => {
  if (process.env.REACT_APP_LOG_LEVEL) {
    const logLevel = LogLevel[process.env.REACT_APP_LOG_LEVEL];
    if (!logLevel) {
      throw new Error('log level must be one of LogLevel.');
    }
    Logger.setLogLevel(logLevel);
  } else {
    throw new Error('log level must be specified.');
  }
};

const checkEnv = () => {
  if (!Env.getApiHost()) {
    throw new Error('api host required.');
  }
  if (process.env.LOCAL_API && !Env.getApiHostLocal()) {
    throw new Error('api host for local required.');
  }
};

export default class Env {
  // TODO 環境情報全部.envに移動
  public static init = () => {
    checkEnv();
    BitkeyPlatformAPI.init((process.env.REACT_APP_BKP_HOST as string) + '/v1', {
      logger: {
        log: console.log,
        warn: console.log,
        error: console.log,
      },
    });
    initLogger();
  };

  public static getApiHost = () => process.env.REACT_APP_API_HOST as string;
  public static getApiHostLocal = () => process.env.REACT_APP_API_HOST_LOCAL as string;

  /**
   * firebase serveしているローカル環境を使う場合。
   * npm start -- --local common
   * みたいに指定するとそこだけ選択的にローカルサーバを使用する。
   * カンマ区切りで複数指定も考えたけど、ポート指定がめんどくさいのと、そもそも各APIサーバは独立しているべきなので、
   * 複数ローカルで動作確認しないといけないシーンがよくないためこれで。
   */
  public static getLocalApi = () => process.env.REACT_APP_LOCAL_API;
}
