import {AxiosRequestConfig} from 'axios';
import {FirebaseFunctions} from '../common/firebase/functions/firebase-functions';

export class Api {
  public static post = <Response, Request>({
    path,
    data,
    config,
    local,
  }: {
    path: string;
    data: Request;
    config?: AxiosRequestConfig;
    local?: boolean;
  }) => {
    if (local) {
      return FirebaseFunctions.postLocal<Response, Request>(path, data, config);
    }
    return FirebaseFunctions.post<Response, Request>(path, data, config);
  };

  public static patch = <Response, Request>({
    path,
    data,
    config,
    local,
  }: {
    path: string;
    data: Request;
    config?: AxiosRequestConfig;
    local?: boolean;
  }) => {
    if (local) {
      return FirebaseFunctions.patchLocal<Response, Request>(path, data, config);
    }
    return FirebaseFunctions.patch<Response, Request>(path, data, config);
  };

  public static get = <Response>({
    path,
    config,
    local,
  }: {
    path: string;
    config?: AxiosRequestConfig;
    local?: boolean;
  }) => {
    if (local) {
      return FirebaseFunctions.getLocal<Response>(path, config);
    }
    return FirebaseFunctions.get<Response>(path, config);
  };
}
