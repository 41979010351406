import {AxiosRequestConfig} from 'axios';
import {ApiAccount} from "../apiAccount";
import { FirebaseFunctions } from '../../../common/firebase/functions/firebase-functions';

const basePath = '/invitations';
// TODO いつかPre外す。
export class ApiAccountInvitations {
  private static post = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiAccount.post<Response, Request>(FirebaseFunctions.makePath(basePath, path), data, config);
  private static patch = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiAccount.patch<Response, Request>(FirebaseFunctions.makePath(basePath, path), data, config);
  private static get = <Response>(path: string, config?: AxiosRequestConfig) =>
    ApiAccount.get<Response>(FirebaseFunctions.makePath(basePath, path), config);

  public static signUp = <Response, Request>(data: any) =>
    ApiAccountInvitations.post<Response, Request>('/sign-up', data);
  public static find = (data: {invitationId: string; userType: 'member' | 'guest'}) =>
    ApiAccountInvitations.post<any, any>(`/find`, {
      ...data,
      key: 'jkladvnkajfvnamkldcajhvunyhh-lckfjvg83047503jv02pp',
    });
  public static accept = (invitationId: string, data: any) =>
    ApiAccountInvitations.post<any, any>(`/${invitationId}/accept`, data);
}
