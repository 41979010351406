import {AxiosRequestConfig} from 'axios';
import {Api} from '../api';
import { FirebaseFunctions } from '../../common/firebase/functions/firebase-functions';
import Env from '../../common/env/env';

const basePath = '/workhubWorkersInfoWeb';
const name = 'workersInfo';


export class ApiWorkersInfo {
  public static post = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) => {
    return Api.post<Response, Request>({
      path: FirebaseFunctions.makePath(basePath, path),
      data,
      config,
      local: Env.getLocalApi() === name,
    });
  };

  public static get = <Response>(path: string, config?: AxiosRequestConfig) => {
    return Api.get<Response>({
      path: FirebaseFunctions.makePath(basePath, path),
      config,
      local: Env.getLocalApi() === name,
    });
  };

  public static patch = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) => {
    return Api.patch<Response, Request>({
      path: FirebaseFunctions.makePath(basePath, path),
      data,
      config,
      local: Env.getLocalApi() === name,
    });
  };
}
