import {AxiosRequestConfig} from 'axios';
import {ApiAccount} from '../apiAccount';
import {
  ApiTypesAuthPostRequest,
  ApiTypesAuthPostResetPasswordRequest,
  ApiTypesAuthPostResetPasswordResponse,
  ApiTypesAuthPostResponse,
  ApiTypesAuthPostVerifyRequest,
  ApiTypesAuthPostVerifyResponse,
} from '@bitkey-service/workhub-types/lib/api/workhub-account-api-types/auth/apiTypesAuth';
import {FirebaseFunctions} from "../../../common/firebase/functions/firebase-functions";
import {MailTemplate} from '../../../bkp/bitkey-platform-api';

const basePath = '/auth';

export class ApiAccountAuth {
  public static post = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiAccount.post<Response, Request>(FirebaseFunctions.makePath(basePath, path), data, config);

  public static loginWithEmail = (data: ApiTypesAuthPostRequest) =>
    ApiAccountAuth.post<ApiTypesAuthPostResponse, ApiTypesAuthPostRequest>('/', data);

  public static verify = () =>
    ApiAccountAuth.post<ApiTypesAuthPostVerifyResponse, ApiTypesAuthPostVerifyRequest>('/verify', {});

  public static bulkResetPassword = async (password: string, bulkResetToken: string, mailTemplate: MailTemplate) =>
    ApiAccountAuth.post<ApiTypesAuthPostResetPasswordResponse, ApiTypesAuthPostResetPasswordRequest>('/password/bulk-reset', {password, bulkResetToken, mailTemplate});
}
