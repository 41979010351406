import * as React from 'react';
import {useCallback, useState} from 'react';
import {WorkhubLogo} from '../../../icons/BIcon';
import {Button, makeStyles} from '@material-ui/core';
import styled from 'styled-components';
import BColor from '../../../styles/b-color';
import SimpleSnackbar, {SnackbarState} from '../../../components/snackbars/SimpleSnackbar';
import {Locale} from '../../../dictionary/dictionary-common';
import {useCommonDictionary, useDictionary} from '../../../dictionary/Dictionary';
import InputResetPasswordTarget from './resetPassword/InputPasswordResetTarget';
import InputPasswordResetAuthenticationCode from './resetPassword/InputPasswordResetAuthenticationCode';

interface P {
  identifyValue: string;
  onCancel: () => any;
}

enum Pages {
  InputPasswordReset = 'InputPasswordReset', // パスワードリセットのidentifyValue入力欄
  InputAuthentication = 'InputAuthentication', // 認証コード入力画面
  SentPasswordReset = 'SentPasswordReset', // email送信後の画面（メールに遷移する）
  CompleteResetPassword = 'CompleteResetPassword', // パスワードリセット完了画面
}

const Label = styled.div`
  font-size: 14px;
  color: ${BColor.MODIFY};
  margin-top: 32px;
  margin-left: 32px;
`;

const Wrapper = styled.div`
  padding: 16px;
  box-sizing: border-box;
`;

const useStyles = makeStyles(() => ({
  input: {
    fontSize: 14,
    height: 32,
    width: '100%',
    marginLeft: 11.38,
    border: 'none',
    outline: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: BColor.LOGIN_INPUT_BORDER,
  },
  welcomeTitle: {
    textAlign: 'center',
    marginTop: 84,
    fontFamily: '"Lato", sans-serif',
    color: BColor.KEY_COLOR,
    fontSize: 14,
    fontWeight: 'bold',
  },
  welcomeLogoWrapper: {
    marginTop: 11,
    display: 'flex',
    justifyContent: 'center',
  },
  welcomeLogo: {
    width: 360 - 116 - 118,
    height: 55,
    color: BColor.KEY_COLOR,
  },
  cancelButton: {
    height: 48,
    borderRadius: 24,
    marginTop: 16,
  },
}));

const dictDef = {
  sentUrlToEmail: {
    default: {
      default: '入力されたメールアドレスにパスワード再発行用のURLを送信しました。',
      [Locale.en_US]: 'URL for resetting password has been sent to the email.',
    },
  },
  successResetPassword: {
    default: {
      default: 'パスワードを再設定しました。',
      [Locale.en_US]: 'Reset password is succeed.',
    },
  },
  backToSignIn: {
    default: {
      default: 'サインイン画面へ戻る',
      [Locale.en_US]: 'Back to SignIn',
    },
  },
};

const PasswordReset: React.FC<P> = props => {
  const commonDict = useCommonDictionary();
  const dict = useDictionary(dictDef);
  const {onCancel} = props;
  const classes = useStyles();
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    open: false,
    message: '',
    success: false,
  });
  const [page, setPage] = useState<Pages>(Pages.InputPasswordReset);
  const [sessionId, setSessionId] = useState<string>('');
  const onSentPasswordReset = useCallback(async (id: string) => {
    setSessionId(id);
    setPage(Pages.SentPasswordReset);
  }, []);
  const onSuccessResetPassword = useCallback(() => setPage(Pages.CompleteResetPassword), []);
  const onFailResetPassword = useCallback((message: string) => {
    setSnackbarState({
      open: true,
      message: message,
      success: false,
    });
  }, []);
  const renderingPasswordResetArea = useCallback(() => {
    switch (page) {
      case Pages.InputPasswordReset:
        return (
          <InputResetPasswordTarget
            identifyValue={props.identifyValue}
            onSentPasswordReset={onSentPasswordReset}
            onCancel={onCancel}
            setSnackbarState={setSnackbarState}
          />
        );
      case Pages.InputAuthentication:
        return (
          <InputPasswordResetAuthenticationCode
            sessionId={sessionId}
            onSuccess={onSuccessResetPassword}
            onFail={onFailResetPassword}
            onCancel={onCancel}
          />
        );
      case Pages.SentPasswordReset:
        return (
          <div>
            <Label>{dict.sentUrlToEmail}</Label>
            <Button
              onClick={onCancel}
              color={'default'}
              variant={'contained'}
              className={classes.cancelButton}
              fullWidth={true}
              href={''}
            >
              {commonDict.cancel}
            </Button>
          </div>
        );
      case Pages.CompleteResetPassword:
        return (
          <div>
            <Label>{dict.successResetPassword}</Label>
            <Button
              onClick={onCancel}
              color={'default'}
              variant={'contained'}
              className={classes.cancelButton}
              fullWidth={true}
              href={''}
            >
              {dict.backToSignIn}
            </Button>
          </div>
        );
      default:
        return <div />;
    }
  }, [classes.cancelButton, commonDict.cancel, dict.backToSignIn, dict.sentUrlToEmail, dict.successResetPassword, onCancel, onFailResetPassword, onSentPasswordReset, onSuccessResetPassword, page, props.identifyValue, sessionId]);
  return (
    <Wrapper>
      <div className={classes.welcomeLogoWrapper}>
        <WorkhubLogo style={{marginTop: 50, marginBottom: 40}} />
      </div>
      {renderingPasswordResetArea()}
      <SimpleSnackbar snackbarState={snackbarState} setSnackbarState={setSnackbarState} />
    </Wrapper>
  );
};

export default React.memo(PasswordReset);
