import {AxiosRequestConfig} from 'axios';
import {FirebaseFunctions} from '../../../common/firebase/functions/firebase-functions';
import {ApiCore} from '../apiv2Core';

const basePath = '/invitations';
// TODO いつかPre外す。
export class ApiCoreInvitations {
  private static post = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiCore.post<Response, Request>(FirebaseFunctions.makePath(basePath, path), data, config);
  private static patch = <Response, Request>(path: string, data: Request, config?: AxiosRequestConfig) =>
    ApiCore.patch<Response, Request>(FirebaseFunctions.makePath(basePath, path), data, config);
  private static get = <Response>(path: string, config?: AxiosRequestConfig) =>
    ApiCore.get<Response>(FirebaseFunctions.makePath(basePath, path), config);

  public static signUp = (data: {password: string; invitationId: string}) =>
    ApiCoreInvitations.post<any, {invitationId: string; password: string}>('/sign-up', data);

  public static find = (invitationId: string, data?: any) => ApiCoreInvitations.get(`/${invitationId}`, data);
  public static update = (peopleId: string, data?: any) => ApiCoreInvitations.patch(`/${peopleId}`, data);
}
