import * as React from 'react';
import {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import {useCommonDictionary, useDictionary} from '../../../../dictionary/Dictionary';
import {SnackbarState} from '../../../../components/snackbars/SimpleSnackbar';
import {Locale} from '../../../../dictionary/dictionary-common';
import CircularIndicator from '../../../../components/progress/CircularIndicator';
import {Button, makeStyles} from '@material-ui/core';
import AuthService from '../../../../service/auth/auth-service';
import BColor from '../../../../styles/b-color';
import styled from 'styled-components';

interface P {
  identifyValue: string;
  onSentPasswordReset: (sessionId: string) => any;
  onCancel: () => any;
  setSnackbarState: Dispatch<SetStateAction<SnackbarState>>;
}

const dictDef = {
  enterEmail: {
    default: {
      default: '登録したメールアドレスを入力してください',
      [Locale.en_US]: 'Please enter registered email',
    },
  },
  enterPhoneNumber: {
    default: {
      default: '登録した電話番号を入力してください',
      [Locale.en_US]: 'Please enter registered phone number',
    },
  },
  resetPassword: {
    default: {
      default: 'パスワードを再発行',
      [Locale.en_US]: 'Reset password',
    },
  },
};

const useStyles = makeStyles(() => ({
  input: {
    fontSize: 14,
    height: 32,
    width: '100%',
    marginLeft: 11.38,
    border: 'none',
    outline: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: BColor.LOGIN_INPUT_BORDER,
  },
  cancelButton: {
    height: 48,
    borderRadius: 24,
    marginTop: 16,
  },
}));

const Label = styled.div`
  font-size: 14px;
  color: ${BColor.MODIFY};
  margin-top: 32px;
  margin-left: 32px;
`;

const Password = styled.div`
  margin-top: 32px;
  border-width: 1px;
  height: 36px;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

const InputResetPasswordTarget: React.FC<P> = props => {
  const {onCancel, onSentPasswordReset, setSnackbarState} = props;
  const commonDict = useCommonDictionary();
  const dict = useDictionary(dictDef);
  const classes = useStyles();
  const [processing, setProcessing] = useState<boolean>(false);
  const [identifyValue, setIdentifyValue] = useState(props.identifyValue);
  const onChangeIdentifyValue = useCallback(e => {
    const inputIdentifyValue = e.target.value;
    setIdentifyValue(inputIdentifyValue);
  }, []);
  const onClickPasswordReset = useCallback(async () => {
    setProcessing(true);
    try {
      const sessionId = await AuthService.issueBulkPasswordResetToken(identifyValue);
      onSentPasswordReset(sessionId);
    } catch (e) {
      setSnackbarState({
        open: false,
        message: '',
        success: false,
      });
    } finally {
      setProcessing(false);
    }
  }, [identifyValue, onSentPasswordReset, setSnackbarState]);
  const onClickCancel = useCallback(() => {
    setIdentifyValue('');
    onCancel();
  }, [onCancel]);
  useEffect(() => setIdentifyValue(props.identifyValue), [props.identifyValue]);
  return (
    <>
      <Label>{dict.enterEmail}</Label>
      <div style={{display: 'flex', justifyContent: 'center'}}>{processing && <CircularIndicator />}</div>
      <Password>
        <input
          name={'email'}
          placeholder={commonDict.email}
          type={'email'}
          value={identifyValue}
          onChange={onChangeIdentifyValue}
          className={classes.input}
          disabled={processing}
        />
      </Password>
      <Button
        id={'loginButton'}
        disabled={!identifyValue || processing}
        onClick={onClickPasswordReset}
        variant={'contained'}
        color={'primary'}
        fullWidth={true}
        style={{marginTop: 32, height: 48, borderRadius: 24}}
        href={''}
      >
        {dict.resetPassword}
      </Button>
      <Button
        onClick={onClickCancel}
        disabled={processing || processing}
        color={'default'}
        variant={'contained'}
        className={classes.cancelButton}
        fullWidth={true}
        href={''}
      >
        {commonDict.cancel}
      </Button>
    </>
  );
};

export default React.memo(InputResetPasswordTarget);
