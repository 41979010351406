import DictionaryCommon, {Dict, DictionaryDefinition, Locale} from './dictionary-common';
import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {DictionaryState} from '../redux/dictionary';
import {Store} from '../redux/store';

const commonDictDef = {
  password: {
    default: {
      default: 'パスワード',
      [Locale.en_US]: 'Password',
    },
  },
  email: {
    default: {
      default: 'メールアドレス',
      [Locale.en_US]: 'Email',
    },
  },
  phoneNumber: {
    default: {
      default: '電話番号',
      [Locale.en_US]: 'Phone Number',
    },
  },
  passwordLengthError: {
    default: {
      default: 'パスワードは半角8文字以上56文字以下で入力してください',
      [Locale.en_US]: 'Please enter 8 characters at least and no more than 56 characters',
    },
  },
  enterHalfWidthLetters: {
    default: {
      default: '使用できない文字が含まれています',
      [Locale.en_US]: 'It contains invalid characters',
    },
  },
  integratingService: {
    default: {
      default: 'サービス連携中...',
      [Locale.en_US]: 'Integrating with the service...',
    },
  },
  cancel: {
    default: {
      default: 'キャンセル',
      [Locale.en_US]: 'Cancel',
    },
  },
};

type CommonDict = typeof commonDictDef;

const useCommonDictionary = (): Dict<CommonDict> => {
  const dictionaryCommon = useMemo(() => new DictionaryCommon(commonDictDef), []);
  const dictState: DictionaryState = useSelector<Store, DictionaryState>(state => state.dictionary);
  const [dict, setDict] = useState<CommonDict>(dictionaryCommon.createDict(dictState.businessType, dictState.locale));
  useEffect(() => {
    setDict(dictionaryCommon.createDict(dictState.businessType, dictState.locale));
  }, [dictState.businessType, dictState.locale, dictionaryCommon]);
  return dict;
};

const useDictionary = <T extends DictionaryDefinition>(dictDef: T): Dict<T> => {
  const dictionaryCommon = useMemo(() => new DictionaryCommon(dictDef), [dictDef]);
  const dictState: DictionaryState = useSelector<Store, DictionaryState>(state => state.dictionary);
  const [dict, setDict] = useState<T>(dictionaryCommon.createDict(dictState.businessType, dictState.locale));
  useEffect(() => {
    setDict(dictionaryCommon.createDict(dictState.businessType, dictState.locale));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dictState.businessType, dictState.locale]);
  return dict;
};

export {useDictionary, useCommonDictionary};
