import React, {useCallback} from 'react';
import {Button, ButtonGroup} from '@material-ui/core';
import Redux from '../../redux/ReduxConnector';
import {Locale} from '../../dictionary/dictionary-common';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  positionRightTop: {
    position: 'absolute',
    top: '2.5rem',
    right: '5rem',
  },
  button: {
    border: '1px solid #828282',
    boxSizing: 'border-box',
    borderRadius: 4,
    height: 38,
    color: '#828282',
  },
}));

const LangButton: React.FC<{}> = () => {
  const classes = useStyles();
  const currentLocale = Redux.getStore().dictionary.locale;

  const changeLang = useCallback(
    (targetLocale: Locale) => () => {
      if (currentLocale === targetLocale) return;
      Redux.actions.dictionary.setLang(targetLocale);
    },
    [currentLocale]
  );

  return (
    <ButtonGroup variant='contained' className={classes.positionRightTop}>
      <Button onClick={changeLang(Locale.ja_JP)} color={currentLocale === Locale.ja_JP ? 'primary' : undefined}>
        日本語
      </Button>
      <Button onClick={changeLang(Locale.en_US)} color={currentLocale === Locale.en_US ? 'primary' : undefined}>
        English
      </Button>
    </ButtonGroup>
  );
};

export default LangButton;
