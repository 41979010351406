import { createMuiTheme } from "@material-ui/core";
import BColor from "./b-color";
import { Overrides } from "@material-ui/core/styles/overrides";
import BFont from "./b-font";

// enumにしておく
export enum DefaultThemeColor {
  inherit = "inherit",
  primary = "primary",
  secondary = "secondary",
  default = "default"
}

export enum ThemeColor {
  success = "success",
  info = "info",
  warning = "warning",
  error = "error"
}

const overrides: Overrides = {
  MuiButton: {
    root: {
      textTransform: "none"
    }
  }
};

/**
 * 何回もtheme作るのコストの無駄なので必要なものは先に作る。
 */
const Themes: { [K in ThemeColor]: any } = {
  success: createMuiTheme({
    palette: {
      primary: {
        main: BColor.SUCCESS,
        light: BColor.SUCCESS_LIGHT,
        dark: BColor.SUCCESS_DARK,
        contrastText: BColor.SUCCESS_CONTRAST
      }
    },
    overrides: overrides
  }),
  info: createMuiTheme({
    palette: {
      primary: {
        main: BColor.INFO,
        light: BColor.INFO_LIGHT,
        dark: BColor.INFO_DARK,
        contrastText: BColor.INFO_CONTRAST
      }
    },
    overrides: overrides
  }),
  warning: createMuiTheme({
    palette: {
      primary: {
        main: BColor.WARNING,
        light: BColor.WARNING_LIGHT,
        dark: BColor.WARNING_DARK,
        contrastText: BColor.WARNING_CONTRAST
      }
    },
    overrides: overrides
  }),
  error: createMuiTheme({
    palette: {
      primary: {
        main: BColor.ERROR,
        light: BColor.ERROR_LIGHT,
        dark: BColor.ERROR_DARK,
        contrastText: BColor.ERROR_CONTRAST
      }
    },
    overrides: overrides
  })
};

export const DefaultTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 980,
      md: 1024,
      lg: 1280,
      xl: 1580
    }
  },
  palette: {
    primary: {
      main: BColor.WorkhubBlue,
      light: BColor.WorkhubBlueLight,
      dark: BColor.WorkhubBlueDark,
      contrastText: BColor.White
    },
    secondary: {
      main: BColor.Sub,
      light: BColor.SubLight,
      dark: BColor.SubDark,
      contrastText: BColor.White
    },
    text: {
      primary: BColor.DEFAULT,
      secondary: BColor.MODIFY,
      hint: BColor.HINT
    }
  },
  typography: {
    fontFamily: BFont.FONT_FAMILY,
    h1: {
      fontSize: 28,
      fontWeight: "bold"
    },
    h2: {
      fontSize: 24,
      fontWeight: "bold"
    },
    h3: {
      fontSize: 20,
      fontWeight: "bold"
    },
    h4: {
      fontSize: 16,
      fontWeight: "bold"
    },
    body1: {
      fontSize: "0.875rem"
    }
  },
  overrides: {
    MuiInputBase: {
      input: {
        height: "1.2em",
        paddingTop: 5
      }
    },
    MuiTypography: {
      root: {
        color: BColor.DEFAULT,
        fontSize: "0.875rem"
      }
    },
    MuiButton: {
      root: {
        textTransform: "none"
      }
    },
    MuiOutlinedInput: {
      inputMarginDense: {
        paddingTop: 10.5,
        paddingBottom: 10.5
      }
    },
    MuiTab: {
      root: {
        textTransform: "none"
      }
    },
    MuiTableCell: {
      body: {
        position: "relative"
      }
    }
  }
});

export default Themes;
