import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import {WorkhubLogo} from '../../../icons/BIcon';
import {makeStyles, useMediaQuery, TextField} from '@material-ui/core';
import styled from 'styled-components';
import BColor from '../../../styles/b-color';
import SimpleSnackbar, {SnackbarState} from '../../../components/snackbars/SimpleSnackbar';
import {AuthenticationType, ProcessState} from './InvitationsForGuest';
import BitLoading from '../../../components/loading/BitLoading';
import AuthService from '../../../service/auth/auth-service';
import {PersonType} from '../Completed';
import {Locale} from '../../../dictionary/dictionary-common';
import {useCommonDictionary, useDictionary} from '../../../dictionary/Dictionary';
import LanguageButton from '../../../components/buttons/LanguageButton';
import {useHistory} from 'react-router-dom';
import Logger from '../../../common/logger/logger';
import PeopleService from '../../../service/people-service';

enum FirestoreOrganizationBusinessType {
  None = 'None', // 読み込む前。基本的に何も表示できない状態。
  Core = 'CORE',
  Residence = 'RESIDENCE',

  // workhub用
  Office = 'office',
}

const Frame = styled.div`
  width: 100vw;
  height: 100vh;
`;

const Box = styled.div`
  position: absolute;
  width: 360px;
  height: 540px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  box-shadow: ${({theme}) => (!theme.isMobile ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : undefined)};
  border-radius: 12px;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: ${BColor.MODIFY};
  margin-top: 64px;
`;

const Passcode = styled.div`
  margin-top: 16px;
  border-width: 1px;
  height: 36px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 16px 32px;
  box-sizing: border-box;
`;

const useStyles = makeStyles(() => ({
  input: {
    fontSize: 14,
    height: 32,
    width: '100%',
    border: 'none',
    outline: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: BColor.LOGIN_INPUT_BORDER,
    borderRadius: 4,
  },
  welcomeTitle: {
    textAlign: 'center',
    marginTop: 64,
    fontFamily: '"Lato", sans-serif',
    color: BColor.KEY_COLOR,
    fontSize: 14,
    fontWeight: 'bold',
  },
  welcomeLogoWrapper: {
    marginTop: 11,
    display: 'flex',
    justifyContent: 'center',
  },
  welcomeLogo: {
    width: 360 - 116 - 118,
    height: 55,
    color: BColor.KEY_COLOR,
  },
}));

const dictDef = {
  invitationInformationNotFound: {
    default: {
      default: '招待情報が見つかりませんでした。',
      [Locale.en_US]: 'Invitation information not found',
    },
  },
  verifyingCode: {
    default: {
      default: '認証コードを認証中です...',
      [Locale.en_US]: 'Verifying the code...',
    },
  },
  before: {
    default: {
      default: '入力した',
      [Locale.en_US]: 'To the entered ',
    },
  },
  after: {
    default: {
      default: '宛に',
      [Locale.en_US]: ',',
    },
  },
  sentCode: {
    default: {
      default: '認証コードを送信しました。',
      [Locale.en_US]: 'authentication code has been sent',
    },
  },
  enterBelow: {
    default: {
      default: '下のフォームにご入力ください。',
      [Locale.en_US]: 'Enter the code below.',
    },
  },
  authenticationCode: {
    default: {
      default: 'パスコード',
      [Locale.en_US]: 'Authentication code',
    },
  },
  errorMessage: {
    default: {
      default: '認証に失敗しました',
      [Locale.en_US]: 'Authentication code',
    },
  },
};

export const EMAIL_PASSCODE_LENGTH = 4;
export const SMS_PASSCODE_LENGTH = 6;

const logger = Logger.create('Invitation Activate Screen');

const UserActivation: React.FC = () => {
  const commonDict = useCommonDictionary();
  const dict = useDictionary(dictDef);
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useMediaQuery('(max-width: 767px)');
  const historyState = useMemo(
    () =>
      history.location.state as {
        businessType;
        authType;
        email;
        phoneNumber;
        password;
        signupSessionId;
        organizationId;
        invitationId;
        createOrganization;
        userId;
        tenantName;
      },
    [history]
  );
  const businessType = useMemo<FirestoreOrganizationBusinessType>(() => historyState?.businessType, [historyState]);
  const createOrganization = useMemo<boolean>(() => historyState?.createOrganization, [historyState]);
  const authType = useMemo<AuthenticationType>(() => historyState.authType || AuthenticationType.Email, [historyState]);
  const passCodeLength = useMemo<number>(
    () => (authType === AuthenticationType.Email ? EMAIL_PASSCODE_LENGTH : SMS_PASSCODE_LENGTH),
    [authType]
  );
  const [passcode, setPasscode] = useState('');
  const [processing, setProcessing] = useState<ProcessState>({
    processing: false,
    message: '',
  });
  const [snackbarState, setSnackbarState] = useState<SnackbarState>({
    open: false,
    message: '',
    success: false,
  });
  const onActivateUser = useCallback(
    async (passcode: string) => {
      if (
        !historyState ||
        (authType === AuthenticationType.Email && !historyState.email) ||
        !historyState.password ||
        !historyState.signupSessionId ||
        !historyState.organizationId ||
        !historyState.invitationId
      ) {
        setSnackbarState({
          open: true,
          message: dict.invitationInformationNotFound,
          success: false,
        });
        return;
      }
      try {
        setProcessing({
          processing: true,
          message: dict.verifyingCode,
        });
        // サインアップの実行
        const activateRes = await AuthService.signUpComplete({
          email: historyState.email,
          password: historyState.password,
          passcode: passcode,
          signupSessionId: historyState.signupSessionId,
          phoneNumber: historyState.phoneNumber,
          authType: authType,
        }).catch(e => {
          logger.log(e);
          throw e;
        });
        setProcessing({
          processing: true,
          message: commonDict.integratingService,
        });
        // サービスイン処理（招待承認）
        // const result = await InvitationsService.accept(
        //   historyState.organizationId,
        //   historyState.invitationId
        // );
        switch (businessType) {
          case FirestoreOrganizationBusinessType.Office:
            await PeopleService.updatePeopleFromGuest({
              peopleId: historyState.invitationId,
              bkpDefaultPersonaId: activateRes.personaId,
              organizationId: historyState.organizationId,
            });
            history.push('/completed', {
              personType: PersonType.guest_temporary,
              sentKeys: [],
            });
            break;
          default:
            history.push('/completed', {
              personType: PersonType.guest_temporary,
              sentKeys: [],
            });
        }
      } catch (e) {
        logger.log(e);
        setSnackbarState({
          open: true,
          message: dict.errorMessage,
          success: false,
        });
      }
      setProcessing({
        processing: false,
        message: '',
      });
    },
    [
      authType,
      businessType,
      commonDict.integratingService,
      createOrganization,
      dict.errorMessage,
      dict.invitationInformationNotFound,
      dict.verifyingCode,
      history,
      historyState,
    ]
  );
  const onChangePasscode = useCallback(
    async e => {
      const code = e.target.value;
      setPasscode(code);
      if (code.length === passCodeLength) {
        await onActivateUser(code);
      }
    },
    [onActivateUser, passCodeLength]
  );

  return (
    <Frame>
      <LanguageButton />
      {processing.processing ? (
        <BitLoading isMobile={isMobile} message={processing.message} />
      ) : (
        <Box theme={{isMobile}}>
          <Wrapper>
            <div className={classes.welcomeLogoWrapper}>
              <WorkhubLogo style={{marginTop: 50, marginBottom: 40}} />
            </div>
            <DescriptionWrapper>
              <div>{`${dict.before}${commonDict.email}${dict.after}`}</div>
              <div>{dict.sentCode}</div>
              <div>{dict.enterBelow}</div>
            </DescriptionWrapper>
            <Passcode>
              <TextField
                name={'password'}
                placeholder={dict.authenticationCode}
                type={'password'}
                variant='outlined'
                size={'small'}
                value={passcode}
                onChange={onChangePasscode}
                style={{width: '100%'}}
              />
            </Passcode>
            {/**}
             <HelpLinkWrapper>
             <Label>メールが届かない場合は</Label>
             <Link component={"button"}>こちら</Link>
             </HelpLinkWrapper>
             */}
          </Wrapper>
        </Box>
      )}
      <SimpleSnackbar snackbarState={snackbarState} setSnackbarState={setSnackbarState} />
    </Frame>
  );
};

export default UserActivation;
