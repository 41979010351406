import BitkeyPlatformAPI from '../../bkp/bitkey-platform-api';
import {ApiAccountInvitations} from '../../api/account/invitations/apiInvitations';
import {FirestoreOrganizationBusinessType} from '@bitkey-service/workhub-types/lib/firestore/organizations/firestoreTypesOrganizations';
import {ApiWorkersInfo} from '../../api/workers-info/apiWorkersInfo';
import {ApiCoreInvitations} from '../../api/v2-core/invitations/apiCoreInvitations';

export interface ServiceInInviteInfo {
  email: string;
  phoneNumber?: string;
  organizationId: string;
  organizationName: string;
  hasBitlockAccount: boolean;
  businessType: FirestoreOrganizationBusinessType;
  createOrganization?: boolean;
  userId: string;
  tenantName?: string;
  personaId?: string;
  // すでに招待メールが送信されているかどうか
  alreadyRegistered: boolean;
  // メンバーが削除されているかどうか
  isDeletedPerson?: boolean;
}

export default class InvitationsService {
  // 明示的にAPIとして用意しているのは、擬似的に向き先をStagingに変更しても
  // httpsCallable関数の向き先は最初にfirebase.initializeAppした時の環境から変化しないため

  public static findInvitation = async ({
    invitationId,
    userType,
  }: {
    invitationId: string;
    userType: 'member' | 'guest';
  }): Promise<ServiceInInviteInfo> => {
    try {
      return await ApiAccountInvitations.find({
        invitationId,
        userType,
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  public static v2FindInvitation = async ({invitationId}: {invitationId: string}): Promise<ServiceInInviteInfo> => {
    return (await ApiCoreInvitations.find(invitationId).catch(e => {
      console.log(e);
      throw e;
    })) as any;
  };

  public static accept = async (organizationId: string, invitationId: string) => {
    const accessToken = await BitkeyPlatformAPI.getAccessToken();
    return await ApiAccountInvitations.accept(invitationId, {
      organizationId: organizationId,
      invitationId: invitationId,
      accessToken: accessToken,
    }).catch(() => {
      throw new Error('招待情報の承認に失敗しました');
    });
  };

  public static signUpWithOffice = async (
    password: string,
    invitationId: string
  ): Promise<{
    personaId: string;
  }> => {
    try {
      const res = await ApiWorkersInfo.post<any, any>(`invitations/sign-up`, {
        key: 'jkladvnkajfvnamkldcajhvunyhh-lckfjvg83047503jv02pp',
        password: password,
        invitationId: invitationId,
      });
      return res.data;
    } catch (e) {
      throw e;
    }
  };

  public static signUpV2 = async (password: string, invitationId: string) => {
    try {
      const res = await ApiCoreInvitations.signUp({
        password: password,
        invitationId: invitationId,
      });
      return res?.data;
    } catch (e) {
      throw e;
    }
  };
}
