import * as React from 'react';
import {useMemo} from 'react';
import styled from 'styled-components';
import {Employee, FaceRecognition} from '../../icons/BIcon';
import {makeStyles, useMediaQuery} from '@material-ui/core';
import BColor from '../../styles/b-color';
import {Locale} from '../../dictionary/dictionary-common';
import {useDictionary} from '../../dictionary/Dictionary';
import LanguageButton from '../../components/buttons/LanguageButton';
import {useHistory} from 'react-router';
import WorkhubMinLogoIcon from '../../icons/WorkhubMinLogoIcon';

const Root = styled.div`
  background-color: ${BColor.WorkhubBlue};
`;

const Frame = styled.div`
  width: 100vw;
  height: 100vh;
`;

const LogoArea = styled.div`
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: pre-line;
`;

const Label = styled.div`
  width: 100%;
  max-width: 215px;
  letter-spacing: 1.5px;
  font-size: 10px;
  color: ${BColor.MODIFY};
`;

const Box = styled.div`
  position: absolute;
  width: 90%;
  max-width: 389px;
  height: 90vh;
  max-height: 607px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 32px;
  margin: auto;
  box-shadow: ${({theme}) => (!theme.isMobile ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : undefined)};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  background-color: ${BColor.White};
`;

const SentKeyAreaWrapper = styled.div`
  margin-top: 32px;
  text-align: center;
  white-space: pre-wrap;
`;

const useStyles = makeStyles(() => ({
  input: {
    fontSize: 14,
    height: 21,
    width: '100%',
    marginLeft: 11.38,
    border: 'none',
    outline: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  welcomeTitle: {
    textAlign: 'center',
    marginTop: 84,
    fontFamily: '"Lato", sans-serif',
    color: BColor.KEY_COLOR,
    fontSize: 14,
    fontWeight: 'bold',
  },
  welcomeLogo: {
    width: 360 - 116 - 118,
    height: 55,
    color: BColor.KEY_COLOR,
  },
  iconsWrapper: {
    marginTop: '3vh',
    display: 'flex',
  },
  centerIcon: {
    fontSize: 48,
    color: BColor.KEY_COLOR,
  },
  icon: {
    fontSize: 48,
    color: BColor.KEY_COLOR,
  },
  joinButton: {
    height: 40,
    borderRadius: 4,
    maxWidth: '168px',
    width: '100%',
    textAlign: 'center',
    boxSizing: 'border-box',
    marginTop: '9vh',
    backgroundColor: `${BColor.WorkhubBlue}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& a': {
      color: `${BColor.White}`,
      textDecoration: 'none',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: 1.25,
    },
  },
  companyName: {
    marginTop: '30px',
    marginBottom: '20px',
    fontSize: '12px',
    fontWeight: 'bold',
    color: `${BColor.WorkhubBlue}`,
  },
  joinTextSection: {
    textAlign: 'left',
    '& h2': {
      margin: 0,
      fontSize: '23px',
      color: `${BColor.WorkhubBlue}`,
      fontWeight: 'normal',
    },
  },
  joinSectionText: {
    marginTop: 20,
    color: `${BColor.MODIFY}`,
    fontSize: 10,
    textAlign: 'center',
    whiteSpace: 'pre-line',
    letterSpacing: 1.5,
  },
}));

export enum PersonType {
  member = 'member',
  guest_temporary = 'guest_temporary',
  guest_customer_representative = 'guest_customer_representative',
  guest_customer_member = 'guest_customer_member',
  cast = 'cast',
  guest_reception = 'guest_reception',
}

export enum FirestoreOrganizationKeyType {
  PRINCIPAL = 'PRINCIPAL',
  ONETIME = 'ONETIME',
}

const dictDef = {
  integratedService: {
    default: {
      default: 'サービスの利用準備が完了しました',
      [Locale.en_US]: 'Ready to use the service',
    },
  },
  followAdmin: {
    default: {
      default: '顔認証やセキュリティカードでの解錠を行う方は管理者の指示に従ってください',
      [Locale.en_US]:
        'For those who use facial recognition or NFC cards to unlock the door,\nplease follow the instructions of the administrator',
    },
  },
  downloadAndWait: {
    default: {
      default: 'アプリ解錠をご利用の方は、\nbitlockアプリをダウンロードして\nカギ・チケットの発行をお待ちください',
      [Locale.en_US]:
        'If you want to unlock the app,\nplease download the bitlock app\nand wait for a key or ticket to be issued.',
    },
  },
  downloadToblifeCrewLinks: {
    default: {
      default: 'toblife crewアプリのダウンロードはこちら',
      [Locale.en_US]: 'toblife crew downloading links are below',
    },
  },
  sentKey: {
    default: {
      default: 'ご利用可能なカギ・チケットを\nbitlockアプリにお届けしました。\nbitlockアプリをダウンロードしてください',
      [Locale.en_US]: 'Available keys and tickets\nhave been delivered to the bitlock app,\ndownload the bitlock app.',
    },
  },
  companyName: {
    default: {
      default: '株式会社ビットキー',
      [Locale.en_US]: 'BitKey Corporation',
    },
  },
  joinSectionTitle: {
    default: {
      default: 'ようこそ、workhubへ',
      [Locale.en_US]: 'Welcome to the workhub.',
    },
  },
  joinSectionText: {
    default: {
      default: 'workhub Cloudへログインができるように\nなりました。',
      [Locale.en_US]: 'You can now login to workhub Cloud.',
    },
  },
};

const Completed: React.FC<{}> = () => {
  const dict = useDictionary(dictDef);
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useMediaQuery('(max-width: 767px)');
  const historyState: {
    organizationName;
  } = useMemo(
    () =>
      history.location.state as {
        organizationName;
      },
    [history]
  );
  return (
    <Root>
      <Frame>
        <LanguageButton />
        <Box theme={{isMobile}}>
          <LogoArea>
            <WorkhubMinLogoIcon size={50} />
          </LogoArea>
          <Description>
            <p className={classes.companyName}>{historyState?.organizationName}</p>
            <section className={classes.joinTextSection}>
              <h2>{dict.joinSectionTitle}</h2>
              <p className={classes.joinSectionText}>{dict.joinSectionText}</p>
            </section>
          </Description>
          <div className={classes.iconsWrapper}>
            <FaceRecognition className={classes.icon} style={{marginRight: 80}} />
            <Employee className={classes.centerIcon} />
          </div>
          <SentKeyAreaWrapper>
            <Label>{dict.followAdmin}</Label>
          </SentKeyAreaWrapper>

          <div className={classes.joinButton}>
            <a href={process.env.REACT_APP_WORKHUB_PATH as string} target={'_blank'}>
              workhub Cloud
            </a>
          </div>
        </Box>
      </Frame>
    </Root>
  );
};

export default Completed;
