import * as React from 'react';
import {useCallback, useState} from 'react';
import {WorkhubLogo} from '../../../icons/BIcon';
import {Button, makeStyles, TextField} from '@material-ui/core';
import styled from 'styled-components';
import BColor from '../../../styles/b-color';
import {AuthenticationType} from './InvitationsForGuest';
import {Locale} from '../../../dictionary/dictionary-common';
import {useCommonDictionary, useDictionary} from '../../../dictionary/Dictionary';

export enum TermsType {
  BitlockConsumer = 'BitlockConsumer',
  FaceImageAuthConsumer = 'FaceImageAuthConsumer',
}

interface P {
  email: string;
  phoneNumber?: string;
  authenticateType: AuthenticationType;
  orgName: string;
  onClickCreateAccount: (password: string) => any;
  shouldDisplayTerm: boolean;
  terms?: TermsType;
}

const Label = styled.div`
  font-size: 14px;
  color: ${BColor.MODIFY};
  margin-top: 16px;
`;

const EmailArea = styled.div`
  margin-top: 16px;
  border-width: 1px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

const Password = styled.div`
  margin-top: 16px;
  border-width: 1px;
  height: 36px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 100%;
`;

const PasswordHint = styled.div`
  color: ${BColor.MODIFY};
  font-size: 12px;
  margin-top: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  padding: 16px 32px;
  box-sizing: border-box;
  flex-direction: column;
`;

const ButtonArea = styled.div`
  height: 128px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const useStyles = makeStyles(() => ({
  input: {
    fontSize: 14,
    height: 32,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  welcomeTitle: {
    textAlign: 'center',
    marginTop: 64,
    fontFamily: '"Lato", sans-serif',
    color: BColor.KEY_COLOR,
    fontSize: 14,
    fontWeight: 'bold',
  },
  welcomeLogoWrapper: {
    marginTop: 11,
    display: 'flex',
    justifyContent: 'center',
  },
}));

const dictDef = {
  sometrhing: {
    default: {
      default: '',
      [Locale.en_US]: '',
    },
  },
  registerBitlock: {
    default: {
      default: 'アカウントを登録し、',
      [Locale.en_US]: 'You will create account, then',
    },
  },
  thenIntegrated: {
    default: {
      default: 'の提供するサービスと連携します',
      [Locale.en_US]: "'s service will be integrated",
    },
  },
  createBitkeyAccount: {
    default: {
      default: 'アカウントを作成する',
      [Locale.en_US]: 'Create account',
    },
  },
};

const SignUp: React.FC<P> = props => {
  const commonDict = useCommonDictionary();
  const dict = useDictionary(dictDef);
  const {email, orgName, onClickCreateAccount} = props;
  const classes = useStyles();
  const [password, setPassword] = useState('');
  const onChangePassword = useCallback(
    e => {
      const password = e.target.value;
      setPassword(password ? password : '');
    },
    [setPassword]
  );
  const onKeyDownPassword = useCallback(
    async e => {
      if (e.keyCode === 13) {
        await onClickCreateAccount(password);
      }
    },
    [password, onClickCreateAccount]
  );
  const signUp = useCallback(() => onClickCreateAccount(password), [onClickCreateAccount, password]);
  const onClickSignUp = useCallback(() => {
    signUp();
  }, [signUp]);

  return (
    <Wrapper>
      <div className={classes.welcomeLogoWrapper}>
        <WorkhubLogo style={{marginTop: 50, marginBottom: 40}} />
      </div>
      <Label>
        <div>{dict.registerBitlock}</div>
        <div>
          {orgName}
          {dict.thenIntegrated}
        </div>
      </Label>
      <EmailArea>
        <TextField
          name={'email'}
          placeholder={commonDict.email}
          type={'email'}
          variant='outlined'
          size={'small'}
          value={email.slice(0, 36) + (email.length > 36 ? '...' : '')}
          disabled={true}
        />
      </EmailArea>
      <Password>
        <TextField
          name={'password'}
          placeholder={commonDict.password}
          type={'password'}
          variant='outlined'
          size={'small'}
          value={password}
          onChange={onChangePassword}
          onKeyDown={onKeyDownPassword}
          style={{width: '100%'}}
        />
      </Password>
      <PasswordHint>{commonDict.passwordLengthError}</PasswordHint>
      <ButtonArea>
        <Button
          id={'loginButton'}
          disabled={!password}
          onClick={onClickSignUp}
          variant={'contained'}
          color={'primary'}
          fullWidth={true}
          style={{marginTop: 24, height: 48, borderRadius: 24}}
          href={''}
        >
          {dict.createBitkeyAccount}
        </Button>
      </ButtonArea>
    </Wrapper>
  );
};

export default SignUp;
