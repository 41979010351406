import {ApiCoreInvitations} from '../api/v2-core/invitations/apiCoreInvitations';

export default class PeopleService {
  public static updatePeopleFromGuest = async ({
    peopleId,
    bkpDefaultPersonaId,
    organizationId,
  }: {
    peopleId: string;
    bkpDefaultPersonaId: string;
    organizationId: string;
  }) => {
    return (await ApiCoreInvitations.update(peopleId, {
      bkpDefaultPersonaId,
      organizationId,
    }).catch(e => {
      console.log(e);
      throw e;
    })) as any;
  };
}
