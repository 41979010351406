export enum LocalStorageKey {
  DEFAULT_PERSONA_ACCESS_TOKEN = 'DEFAULT_PERSONA_ACCESS_TOKEN',
  DEFAULT_PERSONA_REFRESH_TOKEN = 'DEFAULT_PERSONA_REFRESH_TOKEN',
  CUSTOM_TOKEN = 'CUSTOM_TOKEN',
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  // TODO OrganizationIdがtokenから取得できるようになったら（現在はNightly以降）、削除するsuru
  ORGANIZATION_ID = 'ORGANIZATION_ID',
}

export default class LocalStorage {
  public static get = (key: LocalStorageKey) => localStorage.getItem(key);
  public static set = (key: LocalStorageKey, value: string) => localStorage.setItem(key, value);
  public static remove = (key: LocalStorageKey) => localStorage.removeItem(key);
}
