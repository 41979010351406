import * as React from 'react';
import styled from 'styled-components';

const AppLinkWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
`;

const AppleAppLink = styled.a`
  display: inline-block;
  overflow: hidden;
  background: url(https://linkmaker.itunes.apple.com/ja-jp/badge-lrg.svg?releaseDate=2019-05-16&kind=iossoftware&bubble=ios_apps)
    no-repeat;
  width: 135px;
  height: 40px;
`;

const GoogleAppLink = styled.a`
  margin-left: 8px;
`;

const GoogleAppImg = styled.img`
  width: 154px;
  margin-top: 6px;
`;

interface P {
  v2?: boolean;
}

const AppLink: React.FC<P> = props => {
  return (
    <AppLinkWrapper>
      <AppleAppLink href={'https://apps.apple.com/us/app/workhub/id1537057387'} />
      <GoogleAppLink href={'https://play.google.com/store/apps/details?id=jp.bitkey.app.workhub&hl=ja'}>
        <GoogleAppImg
          alt='Google Play で手に入れよう'
          src='https://play.google.com/intl/ja/badges/static/images/badges/ja_badge_web_generic.png'
        />
      </GoogleAppLink>
    </AppLinkWrapper>
  );
};

export default AppLink;
