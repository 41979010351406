import BitkeyPlatformAPI, {
  ActivateUserRequest,
  ActivateUserResponse,
  IssueBulkPasswordResetTokenRequest,
  MailTemplate,
} from '../../bkp/bitkey-platform-api';
import {AuthenticationType} from '../../screens/invitations/guests/InvitationsForGuest';
import LocalStorage, {LocalStorageKey} from '../../local-storage/LocalStorage';
import {FirebaseFunctions} from '../../common/firebase/functions/firebase-functions';
import {ApiAccountAuth} from '../../api/account/auth/apiAccountAuth';

export default class AuthService {
  public static signUpComplete = async (data: {
    email: string;
    password: string;
    passcode: string;
    signupSessionId: string;
    phoneNumber?: string;
    authType: AuthenticationType;
  }): Promise<ActivateUserResponse> => {
    // BKPに対してuserのアクティベート
    const activateRes = await AuthService.activateUser({
      password: data.password,
      public: true,
      authorizationCode: data.passcode,
      signupSessionId: data.signupSessionId,
    }).catch(e => {
      console.log(e);
      throw new Error('認証コードの認証に失敗しました');
    });
    AuthService.updateTokens(activateRes.accessToken, activateRes.refreshToken);
    FirebaseFunctions.setAccessToken(activateRes.accessToken);
    LocalStorage.set(LocalStorageKey.ACCESS_TOKEN, activateRes.accessToken);
    LocalStorage.set(LocalStorageKey.REFRESH_TOKEN, activateRes.refreshToken);
    return activateRes;
  };

  public static signInWithPersonaId = async ({personaId, password}: {personaId: string; password: string}) => {
    try {
      // BKPに対してログイン
      const authRes = await BitkeyPlatformAPI.authenticatePersona(personaId, {
        password,
      });
      AuthService.updateTokens(authRes.accessToken, authRes.refreshToken);
      FirebaseFunctions.setAccessToken(authRes.accessToken);
      LocalStorage.set(LocalStorageKey.ACCESS_TOKEN, authRes.accessToken);
      LocalStorage.set(LocalStorageKey.REFRESH_TOKEN, authRes.refreshToken);
    } catch (e) {
      if (e.data && e.data.lockout) {
        throw Error(
          'アカウントが現在ロックされています。\n「パスワードをお忘れの方はこちら」からパスワードの再設定を行ってください。'
        );
      }
      throw Error('ログインに失敗しました');
    }
  };

  public static signIn = async ({email, password}: {email: string; password: string}) => {
    try {
      // BKPに対してログイン
      const authRes = await BitkeyPlatformAPI.authenticateUser({
        email: email,
        password: password,
      });
      AuthService.updateTokens(authRes.accessToken, authRes.refreshToken);
      FirebaseFunctions.setAccessToken(authRes.accessToken);
      LocalStorage.set(LocalStorageKey.ACCESS_TOKEN, authRes.accessToken);
      LocalStorage.set(LocalStorageKey.REFRESH_TOKEN, authRes.refreshToken);
    } catch (e) {
      if (e.data && e.data.lockout) {
        throw Error(
          'アカウントが現在ロックされています。\n「パスワードをお忘れの方はこちら」からパスワードの再設定を行ってください。'
        );
      }
      throw Error('ログインに失敗しました');
    }
  };

  /**
   * 認証後のトークン更新
   * 読めばすぐわかるけどBitkeyPlatformAPIのupdateTokensにこの処理をセットするのは絶対だめ
   * @param accessToken
   * @param refreshToken
   */
  public static updateTokens = (accessToken, refreshToken) => {
    BitkeyPlatformAPI.setTokens(accessToken, refreshToken);
  };

  public static addUserToBkp = async (data: {email: string; phoneNumber?: string; authType: AuthenticationType}) => {
    try {
      return await BitkeyPlatformAPI.addUser({
        mailTemplate: 'workhub',
        email: data.authType === AuthenticationType.Email ? data.email : undefined,
      });
    } catch (e) {
      if (e && e.data && e.data.code === 400) {
        throw new Error(
          data.authType === AuthenticationType.Email
            ? 'すでに登録されているメールアドレスです'
            : 'すでに登録されている電話番号です'
        );
      }
      console.log(e);
      throw new Error('登録できませんでした');
    }
  };
  public static activateUser = async (activateUserRequest: ActivateUserRequest): Promise<ActivateUserResponse> => {
    try {
      return await BitkeyPlatformAPI.activateUser(activateUserRequest);
    } catch (e) {
      if (e && e.data && e.data.code === 401) {
        throw new Error('パスコードに誤りがあります');
      }
      throw new Error('bitkey Platformへの登録に失敗しました');
    }
  };
  /**
   * パスワード再発行のためのtoken発行処理
   * 指定されたメールアドレスに紐づくペルソナ全てのパスワードをリセットする
   * mailTemplateはいったんapp
   * 現状、BKPのAPIを実行した結果、アプリのDynamicLinkが返却されるので、
   * BKPが改修されるまでは管理画面でパスワード再発行のtoken処理を実行した後にアプリ側で新しいパスワードを入力する必要がある
   * @param identifyValue
   */
  public static issueBulkPasswordResetToken = async (identifyValue: string): Promise<string> => {
    if (identifyValue === '') {
      throw new Error(`メールアドレスを入力してください`);
    }
    const IssueBulkPasswordResetTokenRequest: IssueBulkPasswordResetTokenRequest = {
      email: identifyValue,
      /**
       * 090XXXXXXXXを8190XXXXXXXXに変換してあげないとBKPの認証通らない
       * TODO BKPのバグ直ったらここ直すこと
       */
      mailTemplate: 'workhub',
    };
    try {
      await BitkeyPlatformAPI.issueBulkPasswordResetToken(IssueBulkPasswordResetTokenRequest);
      // emailの場合はsessionIdを使用しないので発行されない
      return '';
    } catch (e) {
      console.log(e);
      throw new Error('パスワードの再発行に失敗しました');
    }
  };

  public static resetPassword = async (token: string, newPassword: string, sessionId: string) => {
    try {
      await BitkeyPlatformAPI.resetPasswordBulkly({
        bulkResetToken: token,
        mailTemplate: 'workhub',
        password: newPassword,
        sessionId: sessionId,
      });
    } catch (e) {
      console.log(e);
      throw new Error('パスワードの再発行に失敗しました');
    }
  };

  public static bulkResetPassword = async (password: string, bulkResetToken: string, mailTemplate: MailTemplate) => {
    await ApiAccountAuth.bulkResetPassword(password, bulkResetToken, mailTemplate);
  };
}
