import * as React from 'react';
import {useCallback, useState} from 'react';
import {WorkhubLogo} from '../../../icons/BIcon';
import {Button, makeStyles, TextField} from '@material-ui/core';
import styled from 'styled-components';
import BColor from '../../../styles/b-color';
import {AuthenticationType} from './InvitationsForGuest';
import Link from '@material-ui/core/Link';
import {Locale} from '../../../dictionary/dictionary-common';
import {useDictionary, useCommonDictionary} from '../../../dictionary/Dictionary';

interface P {
  email: string;
  phoneNumber?: string;
  authenticateType: AuthenticationType;
  orgName: string;
  onLogin: (password: string) => any;
  onClickPasswordReset: () => any;
}

const Label = styled.div`
  font-size: 14px;
  color: ${BColor.MODIFY};
  margin-top: 16px;
`;

const EmailArea = styled.div`
  margin-top: 16px;
  border-width: 1px;
  border-radius: 4px;
  width: 100%;
`;

const Password = styled.div`
  margin-top: 16px;
  border-width: 1px;
  height: 36px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 100%;
`;

const PasswordHint = styled.div`
  color: ${BColor.MODIFY};
  font-size: 12px;
  margin-top: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  box-sizing: border-box;
`;

const PasswordRestLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  justify-content: center;
`;

const PasswordRestLabel = styled.div`
  font-size: 14px;
  color: ${BColor.MODIFY};
`;

const useStyles = makeStyles(() => ({
  input: {
    fontSize: 14,
    height: 32,
    width: '100%',
    border: 'none',
    outline: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: BColor.LOGIN_INPUT_BORDER,
    borderRadius: 4,
  },
  welcomeTitle: {
    textAlign: 'center',
    marginTop: 64,
    fontFamily: '"Lato", sans-serif',
    color: BColor.KEY_COLOR,
    fontSize: 14,
    fontWeight: 'bold',
  },
  welcomeLogoWrapper: {
    marginTop: 11,
    display: 'flex',
    justifyContent: 'center',
  },
  welcomeLogo: {
    width: 360 - 116 - 118,
    height: 55,
    color: BColor.KEY_COLOR,
  },
}));

const dictDef = {
  loginBitlockAnd: {
    default: {
      default: 'bitkeyアカウントでログインし、',
      [Locale.en_US]: 'You will login bitlock account, then',
    },
  },
  thenIntegrated: {
    default: {
      default: 'の提供するサービスと連携します',
      [Locale.en_US]: "'s service will be integrated",
    },
  },
  enterPassword: {
    default: {
      default: '登録済みのパスワードを入力してください',
      [Locale.en_US]: 'Please enter password already registered',
    },
  },
  login: {
    default: {
      default: 'ログインする',
      [Locale.en_US]: 'Login',
    },
  },
  forgotPassword: {
    default: {
      default: 'パスワードをお忘れの方は',
      [Locale.en_US]: 'If you forgot the password, ',
    },
  },
  clickMe: {
    default: {
      default: 'こちら',
      [Locale.en_US]: ' click here',
    },
  },
};

const SignIn: React.FC<P> = props => {
  const commonDict = useCommonDictionary();
  const dict = useDictionary(dictDef);
  const {email, orgName, onLogin, onClickPasswordReset} = props;
  const classes = useStyles();
  const [password, setPassword] = useState('');
  const onChangePassword = useCallback(
    e => {
      const password = e.target.value;
      setPassword(password ? password : '');
    },
    [setPassword]
  );

  const onClickSignIn = useCallback(() => onLogin(password), [onLogin, password]);
  return (
    <Wrapper>
      <div className={classes.welcomeLogoWrapper}>
        <WorkhubLogo style={{marginTop: 50, marginBottom: 40}} />
      </div>
      <Label>
        <div>{dict.loginBitlockAnd}</div>
        <div>
          {orgName}
          {dict.thenIntegrated}
        </div>
      </Label>
      <EmailArea>
        <TextField
          name={'email'}
          placeholder={commonDict.email}
          type={'email'}
          variant='outlined'
          size={'small'}
          value={email.slice(0, 36) + (email.length > 36 ? '...' : '')}
          disabled={true}
          style={{width: '100%'}}
        />
      </EmailArea>
      <Password>
        <TextField
          name={'password'}
          placeholder={commonDict.password}
          type={'password'}
          variant='outlined'
          size={'small'}
          value={password}
          onChange={onChangePassword}
          style={{width: '100%'}}
        />
      </Password>
      <PasswordHint>{dict.enterPassword}</PasswordHint>
      <Button
        id={'loginButton'}
        disabled={!password}
        onClick={onClickSignIn}
        variant={'contained'}
        color={'primary'}
        fullWidth={true}
        style={{marginTop: 24, height: 48, borderRadius: 24}}
        href={''}
      >
        {dict.login}
      </Button>
      <PasswordRestLinkWrapper>
        <PasswordRestLabel>{dict.forgotPassword}</PasswordRestLabel>
        <Link component={'button'} onClick={onClickPasswordReset}>
          {dict.clickMe}
        </Link>
      </PasswordRestLinkWrapper>
    </Wrapper>
  );
};

export default SignIn;
