import * as React from "react";
import styled from "styled-components";
import { CSSProperties, useMemo } from "react";
import BColor from "../../styles/b-color";
import CloudOffIcon from "@material-ui/icons/CloudOff";
import { WorkhubLogo } from "../../icons/BIcon";

const Frame = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const H1 = styled.span`
  font-size: 64px;
  color: ${BColor.MODIFY};
`;

const Title = styled.span`
  font-size: 20px;
  color: ${BColor.MODIFY};
`;

const ReasonMessage = styled.span`
  font-size: 14px;
  color: ${BColor.MODIFY};
  margin-top: 16px;
`;

const LogoWrapper = styled.div`
  position: absolute;
  right: 16px;
  bottom: 8px;
`;

const NotFound: React.FC<{ reason?: string }> = props => {
  const reasonMessage = useMemo(
    () =>
      props.reason
        ? props.reason
        : "URLが間違っているか、ページが存在しません。",
    [props.reason]
  );
  const cloudIconStyle = useMemo<CSSProperties>(
    () => ({
      fontSize: 64,
      color: BColor.MODIFY
    }),
    []
  );
  const logoStyle = useMemo<CSSProperties>(
    () => ({
      fontSize: 64,
      color: BColor.MODIFY
    }),
    []
  );
  return (
    <Frame>
      <CloudOffIcon style={cloudIconStyle} />
      <H1>４０４</H1>
      <Title>お探しのページが見つかりませんでした</Title>
      <ReasonMessage>{reasonMessage}</ReasonMessage>
      <LogoWrapper>
        <WorkhubLogo style={logoStyle} />
      </LogoWrapper>
    </Frame>
  );
};

export default React.memo(NotFound);
