// ディクショナリは基本的にこの持ち方をする

import {BusinessType} from "../domain-base/business-types";

export enum Locale {
  ja_JP = 'ja_JP',
  en_US = 'en_US',
  en_AU = 'en_AU',
}

export const LocaleData: { [locale in Locale]: { label: string, alt?: Locale } } = {
  ja_JP: {label: '日本語'},
  en_US: {label: 'English, US'},
  en_AU: {label: 'English, AU', alt: Locale.en_US},
};

// このへんtypescriptの仕様上やたら冗長。動作を変えずにすっきりさせられるならそうしたい。
export type DefaultLocaleDictDef = { default: any };
export type LocaleDictDef = { [K in Locale]?: any };
export type DefaultBusinessDictDef = { default: DefaultLocaleDictDef & LocaleDictDef };
export type BusinessDictDef = { [K in BusinessType]?: DefaultLocaleDictDef & LocaleDictDef };
export type DictDef = DefaultBusinessDictDef & BusinessDictDef;

export type DictionaryDefinition = {
  [DictKey: string]: DictDef,
};

export type Dict<T extends DictionaryDefinition> = {
  [K in keyof T]: any
};

// TODO 未定義の場合にaltを再帰的に探す処理
export default class DictionaryCommon<T extends DictionaryDefinition> {

  private readonly dictDef: T;

  constructor(dictDef: T) {
    this.dictDef = dictDef;
  }

  public createDict = (businessType, locale: Locale): Dict<T> => {
    // @ts-ignore
    const dict: Dict<T> = {};
    for (const dictKey of Object.keys(this.dictDef)) {
      dict[dictKey as keyof T] = this.getDictValue(dictKey, businessType, locale);
    }
    return dict;
  };

  private searchWord = (businessDict, locale: Locale) => {
    // 空文字にしたい場合もあるかもしれないので厳密に未定義かどうかを確認する
    if (businessDict[locale] !== undefined) {
      return businessDict[locale];
    }
    const alt = LocaleData[locale].alt;
    if (alt) {
      return this.searchWord(businessDict, alt);
    }
    return businessDict.default;
  };

  public getDictValue = (dictKey: string, businessType: string, locale: Locale) => {
    let businessDict;
    if (!businessType || !this.dictDef[dictKey][businessType]) {
      businessDict = this.dictDef[dictKey].default;
    } else {
      businessDict = this.dictDef[dictKey][businessType];
    }
    if (!locale) {
      return businessDict.default;
    }
    return this.searchWord(businessDict, locale);
  };
}
