import React from 'react';

const WorkhubMinLogoIcon = React.memo<{size: number}>(({size}) => (
  <svg width='50' height='51' viewBox='0 0 50 51' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0 8.5C0 4.08172 3.58172 0.5 8 0.5H42C46.4183 0.5 50 4.08172 50 8.5V42.5C50 46.9183 46.4183 50.5 42 50.5H8C3.58172 50.5 0 46.9183 0 42.5V8.5Z'
      fill='#283C4B'
    />
    <path
      d='M29.6653 35.3537C29.3948 35.3537 29.1543 35.2266 29.0842 35.0508L25.4274 27.302C25.3272 27.0968 25.1168 26.96 24.8763 26.96C24.6459 26.96 24.4255 27.0968 24.3253 27.302L20.6985 35.0508C20.6284 35.2071 20.428 35.3537 20.1575 35.3537H19.9872C19.7167 35.3537 19.4762 35.2169 19.4061 35.0214L15.3485 22.4847C15.2483 22.2502 15.2483 22.0645 15.3385 21.9375C15.4287 21.8007 15.609 21.7225 15.8294 21.7225H17.6729C17.9033 21.7225 18.2239 21.8886 18.284 22.1231L20.1174 28.0251C20.1876 28.2596 20.408 28.4257 20.6484 28.4453C20.6585 28.4453 20.6785 28.4453 20.6885 28.4453C20.9189 28.4453 21.1393 28.3085 21.2395 28.1033L24.2451 21.7225C24.3153 21.5466 24.5657 21.3903 24.8262 21.3903H25.0266C25.2971 21.3903 25.5275 21.5173 25.6077 21.7225L28.5732 28.113C28.6734 28.328 28.8838 28.455 29.1243 28.455C29.1343 28.455 29.1543 28.455 29.1643 28.455C29.4148 28.4355 29.6352 28.2694 29.7053 28.0349L31.5087 22.1231C31.649 21.8007 32.0096 21.7225 32.5607 21.7225H33.8932C34.1236 21.7225 34.3139 21.8007 34.4041 21.9473C34.4943 22.0938 34.4943 22.2893 34.4041 22.4847L30.4167 35.0214C30.3465 35.2169 30.1161 35.3537 29.8055 35.3537H29.6653Z'
      fill='white'
    />
    <path
      d='M35.3508 18.3437C35.3508 16.6459 36.7119 15.2813 38.4053 15.2813C40.0829 15.2813 41.4598 16.6459 41.4598 18.3437C41.4598 20.0416 40.0829 21.3903 38.4053 21.3903C36.7119 21.3903 35.3508 20.0416 35.3508 18.3437ZM40.2095 18.3437C40.2095 17.4076 39.3233 16.5348 38.4053 16.5348C37.4399 16.5348 36.5536 17.4234 36.5536 18.3437C36.5536 19.3117 37.4399 20.2002 38.4053 20.2002C39.3233 20.2002 40.2095 19.3117 40.2095 18.3437Z'
      fill='white'
    />
    <path
      d='M43.3233 41.5399C43.3233 41.6219 43.2569 41.6884 43.1749 41.6884L41.589 41.6884C41.0968 41.6884 40.7999 41.9384 40.7999 42.3524C40.7999 42.7508 41.0538 43.0555 41.2218 43.161C41.2569 43.1844 41.2999 43.1962 41.339 43.1962L43.1749 43.1962C43.2647 43.1962 43.3233 43.2547 43.3233 43.3524L43.3233 43.7977C43.3233 43.8758 43.253 43.9462 43.1749 43.9462L38.003 43.9462C37.9249 43.9462 37.8546 43.8758 37.8546 43.7977L37.8546 43.3446C37.8546 43.2665 37.9249 43.1962 38.003 43.1962L40.0694 43.1962C40.1437 43.1962 40.2179 43.1571 40.2569 43.0946C40.2804 43.0594 40.2921 43.0165 40.2921 42.9774C40.2921 42.9462 40.2843 42.911 40.2687 42.8837C40.171 42.6766 40.1202 42.4735 40.1202 42.2626C40.1202 41.4462 40.6749 40.9579 41.6046 40.9579L43.1749 40.9579C43.253 40.9579 43.3233 41.0282 43.3233 41.1063L43.3233 41.5399Z'
      fill='white'
    />
    <path
      d='M43.4013 39.2275C43.4013 40.0439 42.8779 40.4931 41.9248 40.4931L40.3467 40.4931C40.2686 40.4931 40.1982 40.4228 40.1982 40.3447L40.1982 39.8994C40.1982 39.8213 40.2686 39.751 40.3467 39.751L41.9482 39.751C42.6513 39.751 42.7295 39.3017 42.7295 39.1103C42.7295 38.6963 42.4209 38.4228 42.2724 38.3564C42.2451 38.3447 42.2139 38.3369 42.1826 38.3369L40.3467 38.3369C40.2373 38.3369 40.1982 38.2939 40.1982 38.165L40.1982 37.7588C40.1982 37.6807 40.2686 37.6103 40.3467 37.6103L43.1748 37.6103C43.2529 37.6103 43.3232 37.6807 43.3232 37.7588L43.3232 37.9775C43.3232 38.0596 43.2607 38.0908 43.2412 38.0986C43.1826 38.126 43.1435 38.1806 43.1279 38.2392C43.124 38.2588 43.1201 38.2744 43.1201 38.2939C43.1201 38.3369 43.1318 38.3799 43.1592 38.4189C43.3232 38.665 43.4013 38.9267 43.4013 39.2275Z'
      fill='white'
    />
    <path
      d='M43.4015 35.4853C43.4015 35.8173 43.2725 36.0829 43.1632 36.2431C43.1397 36.2782 43.1241 36.3251 43.1241 36.3681C43.1241 36.3837 43.128 36.4032 43.1319 36.4228C43.1475 36.4853 43.1866 36.5361 43.2413 36.5634C43.296 36.5947 43.3233 36.6337 43.3233 36.6845L43.3233 36.9189C43.3233 36.997 43.253 37.0673 43.1749 37.0673L38.003 37.0673C37.9249 37.0673 37.8546 36.997 37.8546 36.9189L37.8546 36.4657C37.8546 36.3876 37.9249 36.3173 38.003 36.3173L39.9757 36.3173C40.046 36.3173 40.1124 36.2861 40.1515 36.2275C40.1788 36.1884 40.1944 36.1415 40.1944 36.0947C40.1944 36.0751 40.1905 36.0517 40.1866 36.0322C40.1398 35.8759 40.1202 35.7236 40.1202 35.5673C40.1202 34.704 40.839 34.0283 41.7608 34.0283C42.6827 34.0283 43.4015 34.665 43.4015 35.4853ZM40.7999 35.6415C40.7999 35.8759 40.8624 36.0673 40.9093 36.1884C40.9444 36.2704 41.0226 36.3212 41.1124 36.3212L42.2022 36.3212C42.2413 36.3212 42.2843 36.3095 42.3155 36.29C42.4132 36.2314 42.7257 36.0087 42.7257 35.579C42.7257 35.0361 42.2374 34.7509 41.7569 34.7509C41.214 34.7509 40.7999 35.1337 40.7999 35.6415Z'
      fill='white'
    />
  </svg>
));

export default WorkhubMinLogoIcon;
