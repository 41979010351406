import bitloading from "../../assets/img/loading/black_bit_loadfinish.gif";
import styled from "styled-components";
import * as React from "react";
import {Typography} from "@material-ui/core";

interface P {
  message?: string;
  isMobile?: boolean;
}

const FlexCenterWrapper = styled.div`
position: absolute;
width: 100%; 
height: 100vh;
top: 0; right: 0; bottom: 0; left: 0;
margin: auto;
box-shadow: ${({theme}) => !theme.isMobile ? '0px 4px 10px rgba(0, 0, 0, 0.3)' : undefined};
border-radius: 12px;
display: flex;
justify-content: center;
align-items: center;
box-sizing: border-box; 
`;

const FlexColumnWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`;

const BitLoadingImg = styled.img`
width: 120px;
`;

const BitLoading: React.FC<P> = ({isMobile, message}) => (
  <FlexCenterWrapper theme={{isMobile}}>
    <FlexColumnWrapper>
      <BitLoadingImg src={bitloading}/>
      <Typography>{message}</Typography>
    </FlexColumnWrapper>
  </FlexCenterWrapper>
);

export default BitLoading;
