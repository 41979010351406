import * as React from 'react';
import {WorkhubLogo} from '../../../icons/BIcon';
import {makeStyles} from '@material-ui/core';
import styled from 'styled-components';
import BColor from '../../../styles/b-color';
import {MobileFriendly} from '@material-ui/icons';
import AppLink from '../../../components/app-link/AppLink';
import {Locale} from '../../../dictionary/dictionary-common';
import {useCommonDictionary, useDictionary} from '../../../dictionary/Dictionary';
import {AuthenticationType} from './InvitationsForGuest';

interface P {
  authType: AuthenticationType;
  identifyValue: string;
  // 招待済みのPeopleが削除されているかどうか
  deletedPerson?: boolean;
  v2?: boolean;
}

const Label = styled.div`
  font-size: 14px;
  color: ${BColor.MODIFY};
  margin-top: 16px;
`;

const EmailArea = styled.div`
  margin-top: 16px;
  border-width: 1px;
  border-radius: 4px;
  width: 100%;
`;

const EmailLabel = styled.div`
  color: ${BColor.MODIFY};
  font-size: 14px;
  font-weight: bold;
`;

const EmailInfoWrapper = styled.div`
  margin-top: 8px;
  color: ${BColor.MODIFY};
  padding: 8px;
  font-size: 14px;
  background-color: ${BColor.GREY_200};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 16px 32px;
  box-sizing: border-box;
`;

const useStyles = makeStyles(() => ({
  welcomeTitle: {
    textAlign: 'center',
    marginTop: 64,
    fontFamily: '"Lato", sans-serif',
    color: BColor.KEY_COLOR,
    fontSize: 14,
    fontWeight: 'bold',
  },
  welcomeLogoWrapper: {
    marginTop: 11,
    display: 'flex',
    justifyContent: 'center',
  },
  welcomeLogo: {
    width: 360 - 116 - 118,
    height: 55,
    color: BColor.KEY_COLOR,
  },
  checkedIcon: {
    marginTop: 16,
    fontSize: 48,
  },
  joinButton: {
    height: 40,
    borderRadius: 4,
    maxWidth: '168px',
    width: '100%',
    textAlign: 'center',
    boxSizing: 'border-box',
    marginTop: '24px',
    marginBottom: '24px',
    backgroundColor: `${BColor.WorkhubBlue}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& a': {
      color: `${BColor.White}`,
      textDecoration: 'none',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: 1.25,
    },
  },
}));

const dictDef = {
  alreadyApproved: {
    default: {
      default: 'この招待は既に承認いただいています。',
      [Locale.en_US]: 'The invitation already approved.',
    },
  },
  alreadyIntegrated: {
    default: {
      default: '連携済みのアカウントです。',
      [Locale.en_US]: 'This account integrated with workhub.',
    },
  },
  alreadyDeleted: {
    default: {
      default: 'アカウント情報が削除されています。',
      [Locale.en_US]: 'This account integrated with workhub.',
    },
  },
  inquiryStatement: {
    default: {
      default: '管理者にお問い合わせください。',
      [Locale.en_US]: 'Please contact the administrator.',
    },
  },
  v2DownloadLinks: {
    default: {
      default: 'workhubアプリのダウンロードはこちら',
      [Locale.en_US]: 'Downloading links are below',
    },
  },

  downloadToblifeCrewLinks: {
    default: {
      default: 'toblife crewアプリのダウンロードはこちら',
      [Locale.en_US]: 'toblife crew downloading links are below',
    },
  },
};

const AlreadyInvited: React.FC<P> = props => {
  const commonDict = useCommonDictionary();
  const dict = useDictionary(dictDef);
  const {identifyValue} = props;
  const classes = useStyles();
  return (
    <Wrapper>
      <div className={classes.welcomeLogoWrapper}>
        <WorkhubLogo style={{marginTop: 50, marginBottom: 40}} />
      </div>
      <Label>
        <div>{props.deletedPerson ? dict.alreadyDeleted : dict.alreadyApproved}</div>
        <div>{props.deletedPerson ? dict.inquiryStatement : dict.alreadyIntegrated}</div>
      </Label>
      <MobileFriendly className={classes.checkedIcon} color={'primary'} />
      <EmailArea>
        <EmailLabel>{commonDict.email}：</EmailLabel>
        <EmailInfoWrapper>{identifyValue.slice(0, 38) + (identifyValue.length > 38 ? '...' : '')}</EmailInfoWrapper>
      </EmailArea>
      <div className={classes.joinButton}>
        <a href={process.env.REACT_APP_WORKHUB_PATH as string} target={'_blank'} rel='noopener noreferrer'>
          workhub Cloud
        </a>
      </div>
      <Label>
        <div>{dict.v2DownloadLinks}</div>
      </Label>
      <AppLink v2={props.v2} />
    </Wrapper>
  );
};

export default AlreadyInvited;
