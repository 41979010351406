import * as React from 'react';
import {Dispatch, SetStateAction, useCallback} from 'react';
import {Snackbar, Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import BColor from '../../styles/b-color';
import styled from 'styled-components';

export interface SnackbarState {
  open: boolean;
  message: string;
  success: boolean;
}

interface P {
  snackbarState: SnackbarState;
  setSnackbarState: Dispatch<SetStateAction<SnackbarState>>;
}

const styles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: BColor.KEY_COLOR,
  },
  error: {
    backgroundColor: BColor.ERROR,
  },
}));

const PreWrapSpan = styled.span`
  white-space: pre-wrap;
`;

const SimpleSnackbar: React.FC<P> = (props: P) => {
  const classes = styles();

  const {snackbarState, setSnackbarState} = props;

  const onClose = useCallback(
    () =>
      setSnackbarState(s => ({
        open: false,
        message: '',
        success: s.success,
      })),
    [setSnackbarState]
  );

  return (
    <Snackbar
      id={snackbarState.success ? 'successSnackBar' : 'errorSnackBar'}
      open={snackbarState.open}
      onClose={onClose}
      autoHideDuration={10000}
      message={<PreWrapSpan>{snackbarState.message}</PreWrapSpan>}
      ContentProps={{
        className: snackbarState.success ? classes.success : classes.error,
      }}
    />
  );
};

export default SimpleSnackbar;
