import {createStore} from "redux";
import {DictionaryState} from './dictionary';
import {CommonDictionaryState} from "./action-reducers/common-dictionary";
import {rootReducer} from './rootReducer';

export interface Store {
  dictionary: DictionaryState,
  commonDictionary: CommonDictionaryState,
}

const store = createStore(
  rootReducer, 
  process.env.NODE_ENV === 'development' && (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

if (process.env.NODE_ENV === 'development' && (module as any).hot) {
  // Enable Webpack hot module replacement for reducers
  (module as any).hot.accept('./rootReducer', () => {
    store.replaceReducer(rootReducer);
  });
}

export {store};
