import React, {useEffect, useState} from 'react';
import Router from "./Router";
import Env from "./env";
import 'react-app-polyfill/ie9';
import {ReduxConnectorComponent} from "./redux/ReduxConnector";
import {store} from "./redux/store";
import {Provider} from "react-redux";

const App: React.FC = () => {
  const [init, setInit] = useState<boolean>(false);
  useEffect(() => {
    Env.init();
    setInit(true);
  }, []);
  return (
    <>
      {init && (
        <Provider store={store}>
          <ReduxConnectorComponent/>
          <Router/>
        </Provider>
      )}
    </>
  );
};

export default App;
