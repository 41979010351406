import ReduxUtil from "./ReduxUtil";
import {BusinessType} from "../domain-base/business-types";
import {Locale} from "../dictionary/dictionary-common";
import moment from 'moment/moment';
import 'moment/locale/ja';

export interface DictionaryState {
  businessType: BusinessType,
  locale: Locale,
}

const getInitialState = (): DictionaryState => ({
  businessType: BusinessType.CORE,
  locale: Locale.ja_JP,
});

const TYPE_PREFIX = 'DICT';
const type = (t) => TYPE_PREFIX + t;

export const DictionaryActions = {
  clear: () => ({type: type('clear')}),
  setBusinessType: (businessType: BusinessType) => ({
    type: type('setBusinessType'),
    businessType: businessType,
  }),
  setLang: (locale: Locale) => {
    // reduxでこういうことやるのよくないのかな・・・
    switch (locale) {
      case Locale.ja_JP:
        moment.locale('ja');
        break;
      case Locale.en_US:
        moment.locale('en');
        break;
      case Locale.en_AU:
        moment.locale('en');
        break;
      default:
        moment.locale('ja');
    }
    return {
      type: type('setLang'),
      locale: locale,
    };
  },
};

const DictionaryReducerFunctions = {
  clear: () => getInitialState(),
  setBusinessType: (state, action) => ({...state, businessType: action.businessType}),
  setLang: (state, action) => ({...state, locale: action.locale}),
};

const dictionaryReducer = ReduxUtil.createReducer(getInitialState(), DictionaryReducerFunctions, TYPE_PREFIX);
export {dictionaryReducer};
