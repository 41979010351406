import * as React from 'react';
import CircularIndicator from '../../../../components/progress/CircularIndicator';
import {Button, makeStyles} from '@material-ui/core';
import {useCommonDictionary, useDictionary} from '../../../../dictionary/Dictionary';
import {Locale} from '../../../../dictionary/dictionary-common';
import BColor from '../../../../styles/b-color';
import {useCallback, useState} from 'react';
import styled from 'styled-components';
import AuthService from '../../../../service/auth/auth-service';

interface P {
  sessionId: string;
  onSuccess: () => any;
  onFail: (message: string) => any;
  onCancel: () => any;
}

const dictDef = {
  sentUrlToEmail: {
    default: {
      default: '電話番号宛に認証コードが送信されました。\n認証コードを入力してください。',
      [Locale.en_US]: 'authentication code is sent to your phone number.\nplease input the code.',
    },
  },
  code: {
    default: {
      default: '認証コード',
      [Locale.en_US]: 'Authentication Code',
    },
  },
  newPassword: {
    default: {
      default: '新しいパスワード',
      [Locale.en_US]: 'New Password',
    },
  },
  passwordMissing: {
    default: {
      default: 'パスワードが未入力です',
      [Locale.en_US]: 'Password is not filled',
    },
  },
  failedReset: {
    default: {
      default: 'パスワードの更新に失敗しました',
      [Locale.en_US]: 'Failed to reset the password',
    },
  },
  enterNewPassword: {
    default: {
      default: '新しく設定するパスワードを入力してください。',
      [Locale.en_US]: 'Please enter the new password.',
    },
  },
  submitCode: {
    default: {
      default: '認証コードを確認',
      [Locale.en_US]: 'Submit Code',
    },
  },
  submitResetPassword: {
    default: {
      default: 'パスワードを再設定する',
      [Locale.en_US]: 'Reset password',
    },
  },
};

const useStyles = makeStyles(() => ({
  input: {
    fontSize: 14,
    height: 32,
    width: '100%',
    marginLeft: 11.38,
    border: 'none',
    outline: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: BColor.LOGIN_INPUT_BORDER,
  },
  cancelButton: {
    height: 48,
    borderRadius: 24,
    marginTop: 16,
  },
}));

const Password = styled.div`
  margin-top: 32px;
  border-width: 1px;
  height: 36px;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

const ErrorField = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorMessage = styled.div`
  color: ${BColor.ERROR};
  font-size: 12px;
`;

const InputPasswordResetAuthenticationCode: React.FC<P> = props => {
  const {sessionId, onSuccess, onFail, onCancel} = props;
  const commonDict = useCommonDictionary();
  const dict = useDictionary(dictDef);
  const classes = useStyles();
  const [processing, setProcessing] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [isInputCode, setIsInputCode] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const onChangeCode = useCallback(e => setCode(e.target.value), []);
  const onChangeNewPassword = useCallback(e => setNewPassword(e.target.value), []);
  const submitAuthenticationCode = useCallback(() => setIsInputCode(true), []);
  const onClickCancel = useCallback(() => {
    setIsInputCode(false);
    setCode('');
    setNewPassword('');
    setErrorMessage('');
    onCancel();
  }, [onCancel]);
  const submitResetPassword = useCallback(async () => {
    setProcessing(true);
    // ここで色々やる
    setErrorMessage('');
    setProcessing(true);
    if (!newPassword) {
      setErrorMessage(dict.passwordMissing);
      setProcessing(false);
      return;
    }
    if (!newPassword.match(/^.{8,56}$/i)) {
      setErrorMessage(commonDict.passwordLengthError);
      setProcessing(false);
      return;
    }

    if (!newPassword.match(/^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_{|}~]+$/i)) {
      setErrorMessage(commonDict.enterHalfWidthLetters);
      setProcessing(false);
      return;
    }
    try {
      await AuthService.resetPassword(code, newPassword, sessionId);
      onSuccess();
    } catch (e) {
      onFail(dict.failedReset);
    } finally {
      setProcessing(false);
    }
  }, [
    sessionId,
    code,
    commonDict.enterHalfWidthLetters,
    commonDict.passwordLengthError,
    dict.failedReset,
    dict.passwordMissing,
    newPassword,
    onFail,
    onSuccess,
  ]);
  return (
    <>
      <div style={{display: 'flex', justifyContent: 'center'}}>{processing && <CircularIndicator />}</div>
      {!isInputCode ? (
        <>
          <Password>
            <input
              name={'code'}
              placeholder={dict.code}
              value={code}
              onChange={onChangeCode}
              className={classes.input}
              disabled={processing}
            />
          </Password>
          <Button
            id={'submitAuthenticationCode'}
            disabled={!code || code.length < 6 || processing}
            onClick={submitAuthenticationCode}
            variant={'contained'}
            color={'primary'}
            fullWidth={true}
            style={{marginTop: 32, height: 48, borderRadius: 24}}
            href={''}
          >
            {dict.submitCode}
          </Button>
        </>
      ) : (
        <>
          <Password>
            <input
              name={'password'}
              type={'password'}
              placeholder={dict.newPassword}
              value={newPassword}
              onChange={onChangeNewPassword}
              className={classes.input}
              disabled={processing}
            />
          </Password>
          <Button
            id={'confirmResetPassword'}
            disabled={!newPassword || processing}
            onClick={submitResetPassword}
            variant={'contained'}
            color={'primary'}
            fullWidth={true}
            style={{marginTop: 32, height: 48, borderRadius: 24}}
            href={''}
          >
            {dict.submitResetPassword}
          </Button>
          {!!errorMessage && (
            <ErrorField>
              <ErrorMessage>{errorMessage}</ErrorMessage>
            </ErrorField>
          )}
        </>
      )}
      <Button
        onClick={onClickCancel}
        disabled={processing || processing}
        color={'default'}
        variant={'contained'}
        className={classes.cancelButton}
        fullWidth={true}
        href={''}
      >
        {commonDict.cancel}
      </Button>
    </>
  );
};

export default React.memo(InputPasswordResetAuthenticationCode);
